import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Home extends Component {
  render() {
    return(
     <div className="content-wrapper mb-2">
      <div className="h-100 row content">
        <div className="col">
            <div className="text-center">
              <h5 className="display-5 pb-3">Ryalmo</h5>
              <img src={require('../../assets/img/laptop.png')} className="rounded pb-3" alt="laptop"/>
            </div>
            <p className="content">Ryalmo provids custom software for businesses.
            <br/>We specialize in web applications, apps, and databases.</p>
            <br/>
            <div className="contact-us-row">
               <Link to="/contact" className="btn btn-primary">
                 Contact Us
               </Link>
            </div>
         </div>
        </div>
      </div>
    );
  }
}