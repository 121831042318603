import http from '../http-common';

class ContactusService {

  contact(data) {
    return http.post('api/contact/Create.php', data);
  }
 
}

export default new ContactusService();

