import React, { Component } from "react";

export default class About extends Component {
  render() {
    return(
    <div className="content-wrapper mb-2">
      <div className="h-100 row content">
        <div className="col">
              <div className="text-center">
                <h5 className="display-5 pb-3">About Ryalmo</h5>
                <img src={require('../../assets/img/laptop.png')} className="rounded pb-3" alt="laptop"/>
              </div>
              <p>Ryalmo is a software company focused on building software for businesses.
              &nbsp;&nbsp;We specialize in web applications, apps, and databases.
              </p>
        </div>
       </div>
      </div>
    );
  }
}
