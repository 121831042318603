import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Ryalmo from './js/ryalmo.js';
//import * as serviceWorker from './serviceWorker.js';

ReactDOM.render(
  <BrowserRouter>
      <Ryalmo />
  </BrowserRouter>,
  document.getElementById('root')
);

//serviceWorker.register();