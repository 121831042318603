import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return(
        <div className="footerMain">
        <footer className="page-footer fixed-bottom">
          <div className="container-fluid w-100">
            <div className="row text-center small">
               <ul className="col mb-3 mt-1 text-uppercase font-weight-bold list-unstyled">
                    <li><a href="/home">Home</a></li>
                    <li ><a href="/about">About</a></li>
                    <li><a href="/contact">Contact us</a></li>
               </ul>
             </div>
             <div className="row text-right small">
               <div className="col mt-2">
                    <a href="/sitemap"><i className="mr-3">Sitemap</i></a>
                    <a href="/privacy-notice"><i className="mr-2">Privacy Notice</i></a>
               </div>
             </div>
          </div>
          <div className="footer-copyright text-center py-3 small">© 2020 Copyright:
              <a href="https://ryalmo.com"> ryalmo.com</a>
          </div>
        </footer>
       </div>
     );
  }
}
