import React, { Component } from "react";

export default class SiteMap extends Component {
  render() {
    return(
    <div className="content-wrapper mb-2">
      <div className="h-100 row content">
        <div className="col">
              <h5 className="display-5 pb-3">Ryalmo Site Map</h5>
              <ul className="sitemap">
                <li><a href="/home">Home</a></li>
                <li><a href="/about">About</a></li>
                <li><a href="/contact">Contact us</a></li>
                <li><a href="/privacy-notice">Privacy Notice</a></li>
              </ul>
        </div>
       </div>
      </div>
    );
  }
}
