import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class ContactSuccess extends Component {
  render() {
    return(
    <div className="content-wrapper mb-2">
      <div className="h-100 row content">
        <div className="col">
              <h5 className="display-5 pb-3">Thank you!</h5>
 	      <p className="content">Thank you for getting in touch! 
               <br/>We will get back in touch with you soon.</p>
               <br/>
               <div className="contact-us-row">
                 <Link to="/home" className="btn btn-primary">
                   Home
                 </Link>
               </div>
         </div>
       </div>
      </div>
    );
  }
}
