import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/css/ryalmo.css";

import Home from "./components/home.component";
import About from "./components/about.component";
import Contact from "./components/contact.component";
import ContactSuccess from "./components/contact.success.component";
import Footer from "./components/footer.component";
import PrivacyNotice from "./components/privacy-notice.component";
import SiteMap from "./components/sitemap.component";

import logo from "../assets/img/logo32.png";

class Ryalmo extends Component {
  state = {
    isOpen: false
  }
  toggleNavbar = () => {
    this.setState( { isOpen: !this.state.isOpen });
  }

  render() {
    
    return (
      <div className="main">
      <div className="content-wrapper">
      <Router>
       <nav className="navbar navbar-expand-sm">

              <Link to={"/"} className="navbar-brand mb-0 h1">
                <img src={logo} className="d-inline-block align-top logo" alt="Ryalmo"/>
                &nbsp;&nbsp;<strong>Ryalmo</strong>
              </Link>

              <button type="button" className="navbar-toggler navbar-toggler-right collapsed"
              data-toggle="collapse" aria-controls="myNavbar" aria-expanded={ this.state.isOpen }
              data-target="#myNavbar" aria-label="Toggle navigation"
              onClick={ this.toggleNavbar }
               >
                <div className={ this.state.isOpen ? 'isOpen' : 'isClosed'}>
                  <span className="close font-weight-normal">X</span>
                  <span className="navbar-toggler-icon">
 			            <span className="line"></span>
                        <span className="line"></span>
                        <span className="line"></span>
                  </span>
                </div>  
              </button>
              <div className={ this.state.isOpen ? 'navbar-collapse' : 'navbar-collapse collapse'} id="myNavbar">
               <ul className="navbar-nav mr-auto nav">
                <li className="nav-item" onClick={ this.toggleNavbar }>
                  <Link to={"/home"} className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item" onClick={ this.toggleNavbar }>
                  <Link to={"/contact"} className="nav-link">
                    Contact us
                  </Link>
                </li>
                <li className="nav-item" onClick={ this.toggleNavbar }>
                  <Link to={"/about"} className="nav-link">
                    About
                  </Link>
                </li>
               </ul>
              </div>
          </nav>
          <hr className="shadow-sm mb-4 hr-class"/>
          <div className="container-fluid mt-3">
              <Switch>
                <Route exact path={["/", "/home"]} component={Home} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/contact/success" component={ContactSuccess} />
                <Route exact path="/about" component={About} />
                <Route exact path="/privacy-notice" component={PrivacyNotice} />
                <Route exact path="/sitemap" component={SiteMap} />
              </Switch>
          </div>
      </Router>
      </div>
      <Footer/>
     </div>
    );
  }
}
export default Ryalmo;