import React, { Component } from "react";

export default class PrivacyNotice extends Component {
  render() {
    return(
    <div className="content-wrapper mb-2">
      <div className="h-100 row policy-content">
        <div className="col">
              <h5 className="display-5 pb-3">PRIVACY NOTICE</h5>
	      <h6 className="display-6 pb-5">Last updated July 15, 2020</h6>	
              <p>
Thank you for choosing to be part of our community at Ryalmo (“Company”, “we”, “us”, or “our”).
 We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please <a href="/contact">contact us</a>.
              </p>
              <p>
When you visit our website <a href="https://ryalmo.com">https://ryalmo.com</a> (the "Website"), and more generally, use any of our services (the "Services", which include the Website), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
              </p>
              <p>
This privacy notice applies to all information collected through our Services (which, as described above, includes our Website), as well as any related services, sales, marketing or events.
              </p>
              <p><strong>
Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.
              </strong></p>
              <h6 className="display-6 pt-3 pb-3 font-weight-bold">TABLE OF CONTENTS</h6>
              <ul className="list-unstyled">
		<li><a href="#pn1">1. WHAT INFORMATION DO WE COLLECT?</a></li>
		<li><a href="#pn2">2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></li>
		<li><a href="#pn3">3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></li>
		<li><a href="#pn4">4. HOW LONG DO WE KEEP YOUR INFORMATION?</a></li>
		<li><a href="#pn5">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></li>
		<li><a href="#pn6">6. DO WE COLLECT INFORMATION FROM MINORS?</a></li>
		<li><a href="#pn7">7. WHAT ARE YOUR PRIVACY RIGHTS?</a></li>
		<li><a href="#pn8">8. CONTROLS FOR DO-NOT-TRACK FEATURES</a></li>
		<li><a href="#pn9">9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a></li>
		<li><a href="#pn10">10. DO WE MAKE UPDATES TO THIS NOTICE?</a></li>
		<li><a href="#pn11">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></li>
              </ul>
              <h6 id="pn1" className="display-6 pt-2 pb-3 font-weight-bold">1. WHAT INFORMATION DO WE COLLECT?</h6>
             <p><strong>Personal information you disclose to us</strong></p>
       <p>
We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Website or otherwise when you contact us.
      </p>
      <p>
The personal information that we collect depends on the context of your interactions with us and the Website, the choices you make and the products and features you use. The personal information we collect may include the following:
      </p>
      <p>
All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.
      </p>
      <p><strong>Information automatically collected</strong></p>
      <p>
We automatically collect certain information when you visit, use or navigate the Website. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our Website and other technical information. This information is primarily needed to maintain the security and operation of our Website, and for our internal analytics and reporting purposes.
      </p>
      <p>
Like many businesses, we also collect information through cookies and similar technologies. 
      </p>
      <h6 id="pn2" className="display-6 pt-3 pb-3 font-weight-bold">2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h6>
      <p>
We may process or share your data that we hold based on the following legal basis:
      </p>
      <ul>
        <li><strong>Consent:</strong>We may process your data if you have given us specific consent to use your personal information in a specific purpose.</li>
        <li><strong>Legitimate Interests:</strong>We may process your data when it is reasonably necessary to achieve our legitimate business interests.</li>
        <li><strong>Performance of a Contract:</strong>Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</li>
        <li><strong>Legal Obligations:</strong>We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</li>
	<li><strong>Vital Interests:</strong>We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
      <p>
More specifically, we may need to process your data or share your personal information in the following situations:
      </p>
      <li><strong>Business Transfers:</strong>We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</li>
      </ul>
      <h6 id="pn3" className="display-6 pt-2 pb-3 font-weight-bold">3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h6>
      <p>
We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
      </p>
      <h6 id="pn4" className="display-6 pt-2 pb-3 font-weight-bold">4. HOW LONG DO WE KEEP YOUR INFORMATION?</h6>
      <p>
We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than 2 years.
      </p>
      <p>
When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
      </p>
      <h6 id="pn5" className="display-6 pt-2 pb-3 font-weight-bold">5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h6>
      <p>
We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Website is at your own risk. You should only access the Website within a secure environment.
     </p>
      <h6 id="pn6" className="display-6 pt-2 pb-3 font-weight-bold">6. DO WE COLLECT INFORMATION FROM MINORS?</h6>
      <p>
We do not knowingly solicit data from or market to children under 18 years of age. By using the Website, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Website. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please <a href="/contact">contact us</a>.
      </p>
      <h6 id="pn7" className="display-6 pt-2 pb-3 font-weight-bold">7. WHAT ARE YOUR PRIVACY RIGHTS?</h6>
      <p>
If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</a>.
      </p>
      <p>
If you are resident in Switzerland, the contact details for the data protection authorities are available here: <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">https://www.edoeb.admin.ch/edoeb/en/home.html</a>.
      </p>
      <p>
<strong><u>Cookies and similar technologies:</u></strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Website. To opt-out of interest-based advertising by advertisers on our Website visit <a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a>.
      </p>
      <h6 id="pn8" className="display-6 pt-2 pb-3 font-weight-bold">8. CONTROLS FOR DO-NOT-TRACK FEATURES</h6>
      <p>
Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track (“DNT”) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
      </p>
      <h6 id="pn9" className="display-6 pt-2 pb-3 font-weight-bold">9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h6>
      <p>
California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.
      </p>
      <p>
If you are under 18 years of age, reside in California, and have a registered account with the Website, you have the right to request removal of unwanted data that you publicly post on the Website. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Website, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).
      </p>
      <h6 id="pn10" className="display-6 pt-2 pb-3 font-weight-bold">10. DO WE MAKE UPDATES TO THIS NOTICE?</h6>
      <p>
We may update this privacy notice from time to time. The updated version will be indicated by an updated “Revised” date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
      </p>
      <h6 id="pn11" className="display-6 pt-2 pb-3 font-weight-bold">11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h6>
      <p>
If you have questions or comments about this notice, you may <a href="/contact">contact us</a>.
      </p>
      <h6 className="display-6 pt-3 pb-3 font-weight-bold">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h6>
       <p>
Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking <a href="/contact">here</a>. We will respond to your request within 30 days.
       </p>
        </div>
       </div>
      </div>
    );
  }
}
