import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button"
import { isEmail } from "validator";

import ContactService from "../services/contact.service";

const required = value => {
  if(!value) {
    return (
      <label className="alert-danger bg-transparent">
        This field is required!
      </label>
    );
  }
};

const validateEmail = value => {
  if(!isEmail(value)) {
    return (
    <label className="alert-danger bg-transparent">
      This is not a valid email.
    </label>
  );
  }
} 
export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.handleContact = this.handleContact.bind(this);
    this.onChangeFullName = this.onChangeFullName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeUserMsg = this.onChangeUserMsg.bind(this);

    this.state = {
      fullName: "",
      email: "",
      userMsg: "",

      submitted: false,
      sysMsg: ""
    };
  }

  onChangeFullName(e) { this.setState({fullName: e.target.value}); }
  onChangeEmail(e) { this.setState({email: e.target.value}); }
  onChangeUserMsg(e) { this.setState({userMsg: e.target.value}); }

  handleContact(e) {
    e.preventDefault();
    this.setState({
      sysMsg: "",
      submitted: false
    });

  this.form.validateAll();

  const sendContactMsg = async () => {
          console.log("starting sendContactMsg");
          const resp = await ContactService.contact(
            {
                fullName: this.state.fullName,
                email: this.state.email,
                msg: this.state.userMsg
            })
            .then( resp => {
                console.log("then.. sendContactMsg");
                this.setState({
                    sysMsg: "Thank you! We will get back to you soon.",
                    submitted: true
                });
                //reset form values before moving to the success page
                this.setState({fullName: "", email: "", userMsg: ""});
                this.props.history.push("/contact/success");
            },
            error => {
                console.log("error.. sendContactMsg");
                const resMsg = (
                    error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();

                console.log(resMsg);

                this.setState({
                    submitted: false,
                    sysMsg: "Your message was not sent.  Please try again later."
                });
            });
            console.log("logging response.. sendContactMsg");
            console.log(resp);
  };

  if(this.checkBtn.context._errors.length === 0) {
        sendContactMsg();
        console.log("after sendContactMsg");
   }
   
 }
  render() {
    return(
      <div className="content-wrapper mb-2">
       <div className="submit-form">
          <h5 className="display-5 pb-3">Contact us</h5>
          <p>We'd love to hear from you!</p>
          <Form
            onSubmit={this.handleContact}
            ref={c => { this.form = c; }}
          >
          <div className="form-group">
            <label htmlFor="fullname">Full Name</label>
            <Input
              type="text"
              className="form-control"
              name="fullname"
              value={this.state.fullName}
              onChange={this.onChangeFullName}
              validations={[required]}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <Input
              type="text"
              className="form-control"
              name="email"
              value={this.state.email}
              onChange={this.onChangeEmail}
              validations={[required, validateEmail]}
            />
          </div>
          <div className="form-group">
            <label htmlFor="usermsg">Message</label>
            <textarea
              className="form-control"
              rows="5"
              maxLength="255"
              name="usermsg"
              value={this.state.userMsg}
              onChange={this.onChangeUserMsg}
              validations={[required]}
            />
          </div>
          <div className="form-group">
            <button
            className="btn btn-primary btn-block"
            disabled={this.state.submitted}
            >
            {this.state.submitted && (
              <span className="btn btn-success"></span>
            )}
            <span>Submit</span>
            </button>
          </div>
          
          {this.state.sysMsg && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {this.state.sysMsg}
              </div>
            </div>
          )}
          <CheckButton
            style={{ display: "none" }}
            ref={c => {
              this.checkBtn = c;
            }}
          />
          </Form>   
      </div>
    </div>
    )
  }

}

